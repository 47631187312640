* {
  font-family: 'Josefin Sans', serif;
  }
  
 body {
	 background: black;
	 color:#86c6e7;
 }

.center {
  display: block;
  text-decoration: none;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#logo {
  max-width: 250px;
  max-height: 250px;
  margin: 70px auto;
}

#logo img {
  height: 100%;
  width: 100%;
}

#response-text {
  transition-duration: 200ms;
  opacity: 0;
  visibility: hidden;
}

#response-text.active {
  opacity: 1;
  visibility: visible;
}

#response-button {
  border: none;
  transition-duration: 200ms;
  opacity: 0;
  visibility: hidden;
  background-color: #86c6e7;
  border: 1px solid #037dd6;
  font-weight: bold;
  color: #037dd6;
  border-radius: 100px;
  margin: 10px auto;
  margin-top: 45px;
  width: 170px;
  padding: 20px;
}

#response-button.active {
  opacity: 1;
  visibility: visible;
}

#response-button:hover {
  cursor: pointer;
  background-color: #1d89ca;
  color: #fdd10f;
}
